import { navigate } from 'gatsby';
import React from 'react';

import { useUser } from '@apis/account/useUser';
import { Button } from '@components/controls/button';
import { Block } from '@components/layout/block';
import { Card } from '@components/layout/card';
import { Center } from '@components/layout/center';
import { VStack } from '@components/layout/vstack';
import { Paragraph } from '@components/typographies/paragraph';
import { useDetectMedia } from '@hooks/useDetectMedia';

const Complete = () => {
  const user = useUser();
  const { md } = useDetectMedia();

  if (!user) {
    return null;
  }

  return (
    <Center>
      <Card
        bg="gray-second"
        width={md ? '730px' : '90%'}
        md={{ width: '719px' }}
        mx={md ? 'auto' : '10px'}
        px={md ? '30px' : '20px'}
        py={md ? '60px' : '20px'}
      >
        <Paragraph fontSize="30px" color="orange" align="center" fontWeight="bold">
          ご注文完了
        </Paragraph>
        <Block paddingTop={md ? '12px' : '28px'} bg="gray-second">
          <Paragraph fontSize="18px" lh="30px" fontWeight="700" align="center" color="black">
            {user.lastName + user.firstName} 様
          </Paragraph>
          <Paragraph fontSize="18px" lh="30px" fontWeight="700" align="center" color="black">
            ご注文ありがとうございます！
          </Paragraph>
        </Block>
        <Block paddingTop={md ? '23px' : '30px'} bg="gray-second">
          <Paragraph fontSize="14px" lh="20px" fontWeight="400" align="center" color="black">
            注文完了メールをお送りしましたので、ご確認ください。
          </Paragraph>
          <Paragraph fontSize="14px" lh="20px" fontWeight="400" align="center" color="black">
            ※届かない場合は迷惑メールフォルダをご確認の上、Tastyにお問い合わせください。
          </Paragraph>
        </Block>
        <Block paddingTop={md ? '28px' : '40px'} bg="gray-second">
          <VStack spacing={md ? '12px' : '15px'}>
            <Button
              onClick={() => navigate('/mypage/history')}
              width="250px"
              height="50px"
              radius="5px"
              color="white"
            >
              <Paragraph fontSize="16px" fontWeight="700" lh="normal" color="white" align="center">
                注文履歴を見る
              </Paragraph>
            </Button>
            <Button
              onClick={() => navigate('/')}
              width="250px"
              height="50px"
              radius="5px"
              color="white"
            >
              <Paragraph fontSize="16px" fontWeight="700" lh="normal" color="white" align="center">
                TOPへ戻る
              </Paragraph>
            </Button>
          </VStack>
        </Block>
      </Card>
    </Center>
  );
};

export default Complete;

import React, { useEffect, useState } from 'react';

import { ReactComponent as TastyLogo } from '@assets/image/svg/tasty-logo.svg';
import { VStack } from '@components/layout/vstack';
import ProgressBar from '@components/progressBar';
import { Paragraph } from '@components/typographies/paragraph';

export const Loading: React.FC = () => {
  const [progress, setProgress] = useState(0);
  const [_, setFrameCount] = useState(0);

  const updateProgress = () => {
    setFrameCount((prevFrameCount) => {
      const newFrameCount = prevFrameCount + 1;
      // Update progress only every 2 frames
      if (newFrameCount % 2 === 0) {
        setProgress((prevProgress) => (prevProgress < 95 ? prevProgress + 1 : 25));
      }
      return newFrameCount;
    });
    requestAnimationFrame(updateProgress);
  };

  useEffect(() => {
    requestAnimationFrame(updateProgress);
  }, []);

  return (
    <VStack spacing="20px" align="center">
      <TastyLogo style={{ width: '134px', height: '100px', flexShrink: 0 }} />
      <ProgressBar progress={progress} />
      <Paragraph width="fit-content" color="gray-800" fontSize="18px" fontWeight="400" lh="normal">
        Loading...
      </Paragraph>
    </VStack>
  );
};

import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet-async';

import { Layout } from '@components/layout';
import { Description, NoIndex, title } from '@components/layout/SEO';
import { Loading } from '@components/loading';
import Complete from '@features/order/Complete';

const CompletePage = () => (
  <Layout>
    <Helmet>
      <title>ご注文完了 | {title}</title>
      {Description}
      {NoIndex}
    </Helmet>
    <Suspense fallback={<Loading />}>
      <Complete />
    </Suspense>
  </Layout>
);

export default CompletePage;

import React, { FC } from 'react';

interface ProgressBarProps {
  progress: number;
}

const ProgressBar: FC<ProgressBarProps> = ({ progress }) => {
  const xValue = progress * 2.5;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="250"
      height="20"
      viewBox="0 0 250 20"
      fill="none"
    >
      <rect width="250" height="19.9999" rx="9.99997" fill="#EEEEEE" />
      <mask
        id="mask0_514_597"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="250"
        height="20"
      >
        <rect width="250" height="19.9999" rx="9.99997" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_514_597)">
        <line
          y1="-6"
          x2="64.1599"
          y2="-6"
          transform="matrix(0.585206 -0.810885 0.907841 0.419314 -1.25 38.0264)"
          stroke="#DDDDDD"
          strokeWidth="12"
        />
        <line
          y1="-6"
          x2="64.1599"
          y2="-6"
          transform="matrix(0.585206 -0.810885 0.907841 0.419314 25 38.0264)"
          stroke="#DDDDDD"
          strokeWidth="12"
        />
        <line
          y1="-6"
          x2="64.1599"
          y2="-6"
          transform="matrix(0.585206 -0.810885 0.907841 0.419314 51.25 38.0264)"
          stroke="#DDDDDD"
          strokeWidth="12"
        />
        <line
          y1="-6"
          x2="64.1599"
          y2="-6"
          transform="matrix(0.585206 -0.810885 0.907841 0.419314 77.4995 38.0264)"
          stroke="#DDDDDD"
          strokeWidth="12"
        />
        <line
          y1="-6"
          x2="64.1599"
          y2="-6"
          transform="matrix(0.585206 -0.810885 0.907841 0.419314 103.75 38.0264)"
          stroke="#DDDDDD"
          strokeWidth="12"
        />
        <line
          y1="-6"
          x2="64.1599"
          y2="-6"
          transform="matrix(0.585206 -0.810885 0.907841 0.419314 130 38.0264)"
          stroke="#DDDDDD"
          strokeWidth="12"
        />
        <line
          y1="-6"
          x2="64.1599"
          y2="-6"
          transform="matrix(0.585206 -0.810885 0.907841 0.419314 156.25 38.0264)"
          stroke="#DDDDDD"
          strokeWidth="12"
        />
        <line
          y1="-6"
          x2="64.1599"
          y2="-6"
          transform="matrix(0.585206 -0.810885 0.907841 0.419314 182.5 38.0264)"
          stroke="#DDDDDD"
          strokeWidth="12"
        />
        <line
          y1="-6"
          x2="64.1599"
          y2="-6"
          transform="matrix(0.585206 -0.810885 0.907841 0.419314 208.749 38.0264)"
          stroke="#DDDDDD"
          strokeWidth="12"
        />
        <line
          y1="-6"
          x2="64.1599"
          y2="-6"
          transform="matrix(0.585206 -0.810885 0.907841 0.419314 235 38.0264)"
          stroke="#DDDDDD"
          strokeWidth="12"
        />
      </g>
      {progress > 0 ? (
        progress > 96 ? (
          <>
            <rect width={xValue} height="19.9999" rx="9.99997" fill="#FBDFB2" />
            <mask
              id="mask1_514_597"
              mask-type="alpha"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width={xValue}
              height="20"
            >
              <rect width={xValue} height="19.9999" rx="9.99997" fill="#F6F4F2" />
            </mask>
          </>
        ) : (
          <>
            <path
              d={`M0 9.99997C0 4.47714 4.47714 0 9.99997 0H${xValue}V19.9999H9.99998C4.47715 19.9999 0 15.5228 0 9.99997Z`}
              fill="#FBDFB2"
            />
            <mask
              id="mask1_514_597"
              mask-type="alpha"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width={xValue}
              height="20"
            >
              <path
                d={`M0 9.99997C0 4.47714 4.47714 0 9.99997 0H${xValue}V19.9999H9.99998C4.47715 19.9999 0 15.5228 0 9.99997Z`}
                fill="#F6F4F2"
              />
            </mask>
          </>
        )
      ) : null}
      {progress > 0 && (
        <g mask="url(#mask1_514_597)">
          {Array.from({ length: Math.floor(progress / 10) + 1 }).map((_, index) => {
            const translateX = index * 26.25 - 1.25;
            return (
              <line
                key={index}
                y1="-6"
                x2="64.1599"
                y2="-6"
                transform={`matrix(0.585206 -0.810885 0.907841 0.419314 ${translateX} 38.0264)`}
                stroke="#F39800"
                strokeWidth="12"
              />
            );
          })}
        </g>
      )}
    </svg>
  );
};

export default ProgressBar;
